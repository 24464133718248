/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        //$('#menu-all-brands').addClass('accordion');
        //$('#menu-all-brands .has-submenu').addClass('');
        //$('#menu-all-brands').data('data-accordion');

        $(document).foundation(); // Foundation JavaScript

        //var elem = new Foundation.DropdownMenu('#menu-all-brands');

        $(function() {

            $('.convert-to-dropdown ul').each(function() {
                var $select = $('<select />').attr('onchange', 'location = this.value;');

                $(this).find('a').each(function() {
                    var $option = $('<option />');
                    $option.attr('value', $(this).attr('href')).html($(this).html());
                    $select.append($option);
                });

                $(this).replaceWith($select);
            });

            $('.convert-to-dropdown').show();
        });


        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        $( ".search-icon" ).click(function() {
          $( "#mobile-search" ).slideToggle( "slow", function() {
            // Animation complete.
          });
        });

        // Smooth Scroll
        $('.scroll-to, .scroll-to a, .pr-snippet-link')
        // Remove links that don't actually link to anything
        .not('[href="#"]')
        .not('[href="#0"]')
        .click(function(event) {
          // On-page links
          if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname ) {
            // Figure out element to scroll to
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            // Does a scroll target exist?
            if (target.length) {
              // Only prevent default if animation is actually gonna happen
              event.preventDefault();
              $('html, body').animate({
                scrollTop: target.offset().top - 40
              }, 1000, function() {
                // Callback after animation
                // Must change focus!
                var $target = $(target);
                $target.focus();
                if ($target.is(":focus")) { // Checking if the target was focused
                  return false;
                } else {
                  $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                  $target.focus(); // Set focus again
                }
              });
            }
          }
        });

        var offset = 250;
        var duration = 600;
         
        $(window).scroll(function() {
          if (jQuery(this).scrollTop() > offset) {
            jQuery('.back-to-top').fadeIn(duration);
          } else {
            jQuery('.back-to-top').fadeOut(duration);
          }
        });
        
        $('.back-to-top').on('click',function(a){
          a.preventDefault();
          jQuery('body,html').animate({ scrollTop: 0 }, 600);
        });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init
        $(document).ready(function(){
          $('.slider').slick({
            infinite: true,
            speed: 500,
            cssEase: 'linear',
            autoplay: true,
            arrows: false,
            dots: true
          });
        });
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'single_product': {
      init: function() {

        $(document).ready(function(){
          $('.woocommerce-product-gallery__image a').attr('data-lightbox', 'product-gallery');
        });

        lightbox.option({
          'positionFromTop': 250,
          'wrapAround': true
        })

        $('.product-carousel').slick({
          dots: true,
          infinite: true,
          speed: 300,
          slidesPerRow: 5,
          rows: 2,
          //slidesToShow: 2,
          //slidesToScroll: 12,
          
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                //slidesToShow: 2,
                //slidesToScroll: 10,
                slidesPerRow: 4,
                rows: 2,
                arrows: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesPerRow: 3,
                rows: 2,
                arrows: false
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesPerRow: 2,
                rows: 1,
                arrows: false
              }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ]
          
        });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
